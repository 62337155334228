/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap");
@import "../node_modules/ngx-toastr/toastr.css";
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  &:focus {
    outline: none;
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

.atom-sidebar {
  border-right: 1px solid #d7d7dc;
  width: 200px !important;
  top:unset !important;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100vh - 48px) !important;
  &.close {
    width: 50px !important;
    .nav-links {
      padding: 0 !important;
      li {
        i {
          min-width: 100% !important;
        }
      }
    }
  }

  li {
    &:hover {
      color: #363636 !important;
      a {
        background-color: #fff !important;
        &:before {
          width: 0 !important;
        }
        i {
          color: #363636 !important;
        }
      }
    }
  }

  .nav-links {
    padding: 0 !important;
    height: auto !important;
    overflow-x: hidden !important;
    .active {
      i{
        color: #d9232d;
      }
      .navListItemTxt{
        color: #d9232d;
      }
    }

  }
}

.container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  max-height: 100vh;
  justify-content: center;
}

.field-with-action-button-container {
  display: flex;
  flex-direction:row;
  justify-content:space-between;
}

.form-header {
  display: flex;
  justify-content: center;
}

.form-action-buttons {
  display: flex;
  justify-content: center;
  margin: 3em;
}

.field{
  margin-right: 1em !important;
}
.table-container {
  max-height: 30vh;
  overflow: auto;
}

table {
  width: 100%;
  max-height: 60%;
  position: relative;
  overflow: auto;
}

.mat-expansion-panel-header.mat-expanded,
.mat-expansion-panel-header.mat-expanded:hover {
  background: #0070c040;
}


.align-items-center {
  align-items: center !important;
}

.align-items-start {
  align-items: start !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-end {
  justify-content: end !important;
}

.d-flex {
  display: flex !important;
}

.w-45 {
  width: 45%;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.w-19 {
  width: 19.2%;
  margin-right: 1%;
  &:nth-child(5n){
    margin-right: 0;
  }
}

/* Hides increment and decrement buttons */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.pointer{
  cursor: pointer;
}
.w-62{
  width: 62.5% !important;
}
.curtain-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid blue;
  width: 95px;
  height: 95px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  display: none;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.curtain {
  height: 100vh;
  width: 100vw;
  z-index: 100;
  background-color: #000;
  opacity: 0.6;
  display: none;
  position: fixed;
}
.status-container {
  background: #ccc;
  text-align: center;
  vertical-align: top;
  border-radius: 14px;
  cursor: pointer;
  display: inline-block;
  padding: 4px 10px;
  max-width: 90;
  font-size: 12px;
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 600;
}
.pending{
  color: #ffffff !important;
  background: #ffc107 0% 0% no-repeat padding-box;
}
.approved{
  color: #ffffff !important;
  background: #28a745 0% 0% no-repeat padding-box;
}
.rejected{
  color: #ffffff !important;
  background: #dc3545 0% 0% no-repeat padding-box;
}
.onboarded{
  color: #ffffff !important;
  background: #8de3ff 0% 0% no-repeat padding-box;
}

:disabled{
  opacity: 0.5;
}

.cdk-global-overlay-wrapper,.cdk-overlay-container{
  z-index: 1000;
}
point-cursor{
  cursor: pointer;
}