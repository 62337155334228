//font family
$rubik : 'Rubik', sans-serif;
$roboto : 'Roboto', sans-serif; 

// ----------------------------------------- color variables
$red : #D9232D;
$black : #3C3C3C;
$blue : #072F5F;
$blue2 : #086AC9;
$yellow : #E3A003;

$blue-hover : #1147C3;
$dark-black : #000;
$black : #333;
$black2 :#3a3a3a;
$white : #fff;
$disabledGrey : #D7D7DC;
$lighGrey: #F5F5F5;
$lighGrey2: #D5D5D5;
$lighGrey3: #E6E6E6;
$input-black : #3A3A3A;
$blue2 : #0881FD;
$blue3 : #0d7ffa;

//------------------------------------------ font weight
$font-300: 300;
$font-400: 400;
$font-500: 500;
$font-600: 600;
$font-700: 700;
$font-800: 800;

//------------------------------------------- Raduis
$raduis-4: 4px;

//-------------------------------------------------- Media

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;


$maxPercantage: 100;
$maxPixel: 2000;
$offset: 1;
$unitPercentage: '%';

@mixin widthPercentage() {
	$i: 1;
	@while $i <= $maxPercantage {
		.w-#{$i} {
			width: #{$i + $unitPercentage};
		}
		$i: $i + $offset;
	}
}
@mixin heightPercentage() {
	$i: 1;
	@while $i <= $maxPercantage {
		.h-#{$i} {
			height: #{$i + $unitPercentage};
		}
		$i: $i + $offset;
	}
}

@mixin widthPixel() {
	$i: 1;
	@while $i <= $maxPixel {
		.w-p#{$i} {
			width: #{$i + px};
		}
		$i: $i + $offset;
	}
}
@mixin heightPixel() {
	$i: 1;
	@while $i <= $maxPixel {
		.h-p#{$i} {
			height: #{$i + px};
		}
		$i: $i + $offset;
	}
}
@mixin TableCellWidth() {
	$i: 1;
	@while $i <= $maxPixel {
		.table-width-p#{$i} {
			min-width: #{$i + px} !important;
			width: #{$i + px} !important;
			max-width: #{$i + px} !important;
		}
		$i: $i + $offset;
	}
}

@mixin TableCellWidthPercentage() {
	$i: 1;
	@while $i <= $maxPercantage {
		.table-width-#{$i} {
			min-width: #{$i + $unitPercentage} !important;
			width: #{$i + $unitPercentage} !important;
			max-width: #{$i + $unitPercentage} !important;
		}
		$i: $i + $offset;
	}
}


@include widthPercentage();
@include heightPercentage();
@include widthPixel();
 @include heightPixel();
 @include TableCellWidth();
 @include TableCellWidthPercentage();


 @mixin sm {
    @media (max-width: #{$screen-sm-min}) {
        @content;
    }
 }
 
 // Medium devices
 @mixin md {
    @media (max-width: #{$screen-md-min}) {
        @content;
    }
 }
 
 // Large devices
 @mixin lg {
    @media (max-width: #{$screen-lg-min}) {
        @content;
    }
 }
 
 // Extra large devices
 @mixin xl {
    @media (max-width: #{$screen-xl-min}) {
        @content;
    }
 }