// All Buttons
.btn-primary {
  @extend .text-sm;
  background-color: $blue;
  height: 33px !important; // default btn height
  min-width: 30px !important;
  padding: 6px 16px !important;
  border-radius: 4px !important;
  letter-spacing: unset;
  font-family: $roboto !important;
  color: $white;
  line-height: 6px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center;

  .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.btn-rectangle {
    height: 30px !important;
    width: 30px !important;
    padding: 0px !important;
    i {
      font-size: 18px !important;
      line-height: 13px !important;
    }
    &.btn-md {
      height: 40px !important;
      width: 40px !important;
    }
    &.btn-lg {
      height: 50px !important;
      width: 50px !important;
    }
  }
  &.btn-circle {
    @extend .btn-rectangle;
    border-radius: 50% !important;
  }

  &.mat-raised-button.mat-warn,
  &.mat-raised-button.mat-Red {
    background-color: $red;
    &.btn-hover,
    &:hover {
      background-color: #e14f57;
    }
    &.btn-focus,
    &:focus {
      outline: 2px solid #ec9196;
    }
  }

  &.mat-raised-button.mat-Black {
    background-color: $black;
    &.btn-hover,
    &:hover {
      background-color: #6f6f6f;
    }
    &.btn-focus,
    &:focus {
      outline: 2px solid #6f6f6f !important;
    }
  }

  &.mat-raised-button.mat-Blue {
    background-color: $blue;
    &.btn-hover,
    &:hover {
      background-color: #20446f;
    }
    &.btn-focus,
    &:focus {
      outline: 2px solid #6a829f !important;
    }
  }

  &.mat-raised-button.mat-Blue2,
  &.mat-raised-button.mat-primary {
    background-color: $blue2;
    &.btn-hover,
    &:hover {
      background-color: #288bed;
    }
    &.btn-focus,
    &:focus {
      outline: 2px solid #288bed !important;
    }
  }

  &.mat-raised-button.mat-Yellow {
    background-color: $yellow;
    &.btn-hover,
    &:hover {
      background-color: #fecc2f;
    }
    &.btn-focus,
    &:focus {
      outline: 2px solid #fecc2f !important;
    }
  }

  &.mat-raised-button.success {
    background-color: #28a745 !important;
    &.btn-hover,
    &:hover {
      background-color: #28a745;
    }
    &.btn-focus,
    &:focus {
      outline: 2px solid #28a745 !important;
    }
  }
  &.mat-raised-button.error {
    background-color: $red !important;
    &.btn-hover,
    &:hover {
      background-color: $red;
    }
    &.btn-focus,
    &:focus {
      outline: 2px solid $red !important;
    }
  }
  &.mat-raised-button.information {
    background-color: #0779ff !important;
    &.btn-hover,
    &:hover {
      background-color: #0779ff;
    }
    &.btn-focus,
    &:focus {
      outline: 2px solid #0779ff !important;
    }
  }
  &.mat-raised-button.warning {
    background-color: #ef9400 !important;
    color: #212529 !important;
    &.btn-hover,
    &:hover {
      background-color: #ef9400;
    }
    &.btn-focus,
    &:focus {
      outline: 2px solid #ef9400 !important;
    }
  }
  &.mat-button-disabled {
    background-color: $disabledGrey !important;
    color: $white !important;
  }
  &.mat-raised-button:disabled {
    background-color: $disabledGrey !important;
    color: $white !important;
  }

  i {
    @extend .text-lg;

    &.icon-left {
      margin-right: 8px;
    }

    &.icon-right {
      margin-left: 8px;
    }
  }

  &.btn-md {
    height: 43px !important;
  }
  &.btn-lg {
    height: 55px !important;
  }
  &.mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
  &.mat-stroked-button:hover:not(.mat-button-disabled)
    .mat-button-focus-overlay {
    opacity: 0 !important;
  }
}

.btn-outline-primary {
  @extend .btn-primary;
  background-color: $white;
  color: $blue;
  border: 1px solid $blue;

  &.mat-stroked-button.mat-warn,
  &.mat-stroked-button.mat-Red {
    color: $red;
    border-color: $red;
    &.btn-hover,
    &:hover {
      background-color: #fff7f7;
    }
    &.btn-focus,
    &:focus {
      outline: 2px solid #ec9196;
      border-color: transparent;
    }
  }

  &.mat-stroked-button.mat-Black {
    color: $black;
    border-color: $black;
    &.btn-hover,
    &:hover {
      background-color: #efeff4;
    }
    &.btn-focus,
    &:focus {
      outline: 2px solid #c4c4c4;
      border-color: transparent;
    }
  }
  &.mat-stroked-button.mat-Blue {
    color: $blue;
    border-color: $blue;
    &.btn-hover,
    &:hover {
      background-color: #f0f7ff;
    }
    &.btn-focus,
    &:focus {
      outline: 2px solid #6a829f;
      border-color: transparent;
    }
  }
  &.mat-stroked-button.mat-Blue2,
  &.mat-stroked-button.mat-primary {
    color: $blue2;
    border-color: $blue2;
    &.btn-hover,
    &:hover {
      background-color: #f0f7ff;
    }
    &.btn-focus,
    &:focus {
      outline: 2px solid #086ac9;
      border-color: transparent;
    }
  }
  &.mat-stroked-button.mat-Yellow {
    color: $yellow;
    border-color: $yellow;
    &.btn-hover,
    &:hover {
      background-color: #fffaed;
    }
    &.btn-focus,
    &:focus {
      outline: 2px solid #e3a003;
      border-color: transparent;
    }
  }

  &.mat-stroked-button:disabled {
    border: 1px solid $disabledGrey !important;
    background-color: transparent !important;
    color: $disabledGrey !important;
  }
}

.btn-basic {
  @extend .btn-primary;
  border: unset !important;
  background-color: $white;
  color: $blue;
  .mat-button-wrapper {
    display: flex;
    align-items: center;
  }
  &.mat-button.mat-warn,
  &.mat-button.mat-Red {
    color: $red;

    &.btn-hover,
    &:hover {
      background-color: #fff7f7;
    }
    &.btn-focus,
    &:focus {
      outline: 2px solid #ec9196;
    }
  }
  &.mat-button.mat-Black {
    color: $black;
    &.btn-hover,
    &:hover {
      background-color: #efeff4;
    }
    &.btn-focus,
    &:focus {
      outline: 2px solid #c4c4c4;
    }
  }
  &.mat-button.mat-Blue {
    color: $blue;
    &.btn-hover,
    &:hover {
      background-color: #f0f7ff;
    }
    &.btn-focus,
    &:focus {
      outline: 2px solid #6a829f;
    }
  }
  &.mat-button.mat-Blue2,
  &.mat-button.mat-primary {
    color: $blue2;
    &.btn-hover,
    &:hover {
      background-color: #f0f7ff;
    }
    &.btn-focus,
    &:focus {
      outline: 2px solid #9cc2e9;
    }
  }
  &.mat-button.mat-Yellow {
    color: $yellow;
    &.btn-hover,
    &:hover {
      background-color: #fffaed;
    }
    &.btn-focus,
    &:focus {
      outline: 2px solid #f4d99a;
    }
  }

  &.btn-text-black {
    color: $black !important;
  }

  &.btn-basic:disabled {
    background-color: transparent !important;
    color: $disabledGrey !important;
  }
}

.atom-anchor {
  @extend .text-sm;
  font-weight: $font-500 !important;
  color: $blue !important;
  cursor: pointer;
}

// form input group label start
.input-group {
  position: relative;
  @extend .flex-col;
  @extend .flex;
  width: 100%;

  mat-label {
    @extend .mb-5 !optional;
    @extend .text-xs;
    margin-bottom: 0;
  }
}

// forms input group label end

//mat input start
.mat-input {
  width: 100%;
  .mat-form-field-wrapper {
    background: $white !important;
    // @extend .input-border;
    @extend .rounded;

    .mat-input-element {
      @extend .input-text;
      caret-color: auto;
      &::placeholder {
        @extend .text-xs;
        line-height: 1.25rem !important; /* 20px */
      }
    }
  }

  &.mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: #0580fa;
  }

  .mat-form-field-infix {
    padding-top: 10px !important;
    padding-bottom: 6px !important;
    min-height: unset;
    border-top: unset !important;
    width: auto;
    @extend .text-xs;
    line-height: 1.25rem !important; /* 20px */
  }

  .mat-form-field-focus-overlay {
    background-color: transparent !important;
  }

  &.mat-form-field-disabled {
    .mat-form-field-wrapper {
      background-color: $lighGrey3 !important;
      border-color: $lighGrey3 !important;
      padding-bottom: 0px;
    }
  }
  .mat-form-field-suffix {
    top: 5px !important;
    .mat-icon-button {
      height: 1.5em !important;
      width: 1.5em !important;
      font-size: 12px !important;
    }
  }
  &.icon {
    .mat-form-field-infix {
      display: flex;
    }

    i {
      @extend .relative;

      font-size: 16px;

      &:first-child {
        @extend .mr-10;
      }

      &:last-child {
        @extend .ml-10;
      }
    }
  }
  &.timepicker-icon {
    .mat-form-field-infix {
      display: flex;
    }

    i {
      @extend .relative;
      color: #757575;
      font-size: 16px;

      &:first-child {
        @extend .mr-10;
      }

      &:last-child {
        @extend .ml-10;
      }
    }
  }
  &.deep-0 {
    .mat-form-field-wrapper {
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  }
}

//mat input end

//mat select option start

.mat-select {
  @extend.mat-input;
  &.selected-highlight {
    &.mat-form-field-should-float {
      .mat-form-field-outline-start,
      .mat-form-field-outline-end {
        border-color: $red;
        background-color: #fcf4f4;
      }
      .mat-select-value {
        color: $red;
      }
      .mat-select-arrow {
        color: $red;
      }
    }
  }
  mat-select {
    @extend .text-xs;
    line-height: 1.25rem !important; /* 20px */
  }

  .mat-select-arrow-wrapper {
    transform: translateY(4%) !important;
  }

  .mat-error {
    color: #d7242d !important;
  }

  &.mat-form-field-invalid {
    .mat-form-field-outline-thick,
    .mat-select-arrow {
      color: #d7242d !important;
    }
  }

  .mat-form-field-subscript-wrapper {
    top: 36px !important;
  }
}

.mat-option {
  min-height: 34px !important;
  height: 34px !important;
  @extend .text-xs;

  &.mat-selected:not(.mat-option-disabled) {
    color: #0580fa !important;
  }

  .mat-pseudo-checkbox-checked,
  .mat-pseudo-checkbox-indeterminate {
    background: $blue3 !important;
    color: $blue3 !important;
  }

  &.mat-selected:not(.mat-option-disabled) {
    color: $blue3 !important;
  }
}
.select-panel-class {
  max-height: 340px !important;
  .mat-input {
    padding: 5px 7px;
    max-width: 100%;
    box-sizing: border-box;
    .mat-form-field-wrapper {
      padding-bottom: 0px;
      margin-bottom: 0px;
    }
  }
  .select-options {
    display: block;
    height: 240px;
    overflow: auto;
    .select-label {
      padding: 5px 10px;
      color: #bababa;
      font-size: 12px;
      line-height: 16px;
      position: relative;
      width: 100%;
      display: block;
      box-sizing: border-box;
      .select-label-text {
        background-color: white;
        height: 100%;
        display: inline-block;
      }
      &::after {
        content: "";
        position: absolute;
        display: block;
        left: 61px;
        right: 0;
        top: 50%;
        background-color: #00000029;
        height: 0.1em;
        min-height: 1px;
        z-index: -2;
        width: calc(100% - 69px);
      }
    }
  }
  .select-footer {
    padding: 10px;
    border-top: 1px solid #838383;
    display: flex;
    justify-content: flex-end;
  }
}
//mat select option start

//mat select input combine start
.mat-select-input {
  display: flex;

  .mat-form-field-type-mat-select.mat-select {
    width: 40%;

    .mat-form-field-outline-end {
      border-right: 1px solid #d5d5d5 !important;
    }

    .mat-form-field-outline-thick {
      .mat-form-field-outline-end {
        border-color: $blue3 !important;
      }
    }

    .mat-form-field-outline-end {
      border-radius: 0 !important;
      border-right: unset;
    }
  }

  .mat-form-field-type-mat-input {
    width: 60%;

    .mat-form-field-outline-start {
      border-left: unset;
      border-radius: 0px;
      border-left: 1px solid #d5d5d5;
    }

    .mat-form-field-outline-thick {
      .mat-form-field-outline-start {
        border-color: $blue3 !important;
      }
    }
  }
}

//mat select input combine start

// mat chip input start
.mat-chips {
  @extend .mat-input;

  .mat-chip {
    background-color: #eaf4ff;
    @extend .text-xs;
    line-height: 1.25rem !important; /* 20px */
    line-height: 10px;
    color: $black2;
    border-radius: 0px;
    margin: 3px !important;
    min-height: 22px;
    padding: 6px !important;
    @extend .text-xs;

    .mat-chip-remove {
      opacity: 1;
      display: flex;
      align-items: center;

      i {
        @extend .text-sm;
        color: #000000;
      }
    }
  }
}

// mat chip input end

// mat input with button start
.mat-input-button {
  @extend .mat-input;
  i {
    &:first-child {
      display: flex;
      align-items: center;
    }
  }
  .mat-form-field-flex {
    padding-right: 4px !important;
    padding-bottom: 1px !important;
  }
  .mat-form-field-infix {
    display: flex;
    padding-top: 7px !important;
    padding-bottom: 3px !important;

    .mat-input-element {
      padding-right: 6px;
    }

    button {
      min-width: auto;
    }
  }
}

// mat input with button end
// selection type checkbox start
.mat-checkbox-checked,
.mat-checkbox-indeterminate {
  .mat-checkbox-background,
  .mat-ripple-element {
    background-color: $blue2 !important;
  }
}

.selection-group {
  .mat-checkbox-checked,
  .mat-checkbox-indeterminate {
    .mat-checkbox-background,
    .mat-ripple-element {
      background-color: $blue2 !important;
    }
  }

  .mat-checkbox-ripple {
    display: none;
  }

  .mat-checkbox {
    @extend .text-xs;
  }

  ul {
    list-style: none;
    padding-left: 0px;
    margin: 0;
    @extend .text-sm;
  }
}

// selection type checkbox end

// radio button row start
* .mat-radio-row {
  .mat-radio-label {
    @extend .text-xs;
  }

  .mat-radio-button {
    margin-right: 20px;
  }
  .mat-radio-outer-circle {
    height: 18px;
    width: 18px;
  }
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: $blue3 !important;
  }
  .mat-radio-container {
    width: 18px !important;
    height: 18px !important;
  }
  .mat-radio-button.mat-accent .mat-radio-inner-circle {
    opacity: 1;
    width: 18px;
    height: 18px;
  }
  .mat-radio-button.mat-accent .mat-radio-inner-circle,
  .mat-radio-button.mat-accent
    .mat-radio-ripple
    .mat-ripple-element:not(.mat-radio-persistent-ripple),
  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
  .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: $blue3;
  }

  .mat-radio-outer-circle {
    border-color: #000000;
    border-width: 2px !important;
  }
}

.mat-radio-column {
  @extend .mat-radio-row;
  flex-direction: column !important;
  @extend .flex;

  .mat-radio-button {
    margin-bottom: 10px;
  }
}

// radio button row end
// mat slide toggle start
.mat-slider-toggle {
  .mat-slide-toggle-bar {
    width: 30px !important;
    height: 12px !important;
    border: 2px solid $red !important;
    background-color: $red !important;
    box-sizing: content-box;
  }
  .mat-slide-toggle-content {
    @extend .text-xs;
  }
  .mat-slide-toggle-thumb-container {
    top: 0px !important;
    left: 0px !important;
    width: 18px !important;
    height: 18px !important;
  }

  .mat-slide-toggle-thumb {
    height: 12px !important;
    width: 12px !important;
    background-color: #fff !important;
  }

  .mat-slide-toggle-ripple {
    display: none !important;
  }

  .mat-slide-toggle .mat-slide-toggle-thumb {
    height: 12px !important;
    width: 12px !important;
  }

  mat-label {
    @extend .text-xs;
    line-height: 16px;
    color: #1e1e1e;
    margin-left: 10px;
  }

  &.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
    background-color: #3cae3c !important;
    border: 2px solid #3cae3c !important;
  }

  &.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
    background-color: #fff !important;
  }

  &.mat-slide-toggle .mat-slide-toggle-thumb-container {
    top: 0px !important;
    left: 0px !important;
    width: 18px !important;
    height: 18px !important;
  }

  &.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    transform: translate3d(18px, 0, 0) !important;
  }
}

// mat slide toggle end

// mat menu start
.btn-menu {
  width: 100%;
  text-align: left !important;
  margin: 0.25em 0 1.59375em !important;
  padding: 0 0.75em !important;
  @extend .text-xs;
  font-weight: 400;
  line-height: 32.5px !important;
  @extend .input-border;

  .mat-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    i {
      font-size: 20px;
      color: #5a5a5a;
    }
    & > span {
      display: block;
      width: calc(100% - 20px);
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.mat-menu {
  min-width: 204px !important;
  padding: 4px 6px;
  max-height: 305px !important;

  .mat-menu-content {
    padding: 0px !important;

    .mat-input {
      .mat-form-field-wrapper {
        padding-bottom: 0px !important;
      }
    }

    .mat-radio-group {
      margin-left: 10px;

      .mat-radio-label {
        @extend .text-xs;
      }

      .mat-radio-container {
        height: 15px !important;
        width: 15px !important;
      }

      .mat-radio-outer-circle {
        height: 15px !important;
        width: 15px !important;
      }

      .mat-radio-inner-circle {
        height: 15px !important;
        width: 15px !important;
      }

      .mat-radio-button .mat-radio-ripple {
        height: 30px !important;
        width: 30px !important;
        left: calc(50% - 15px) !important;
        top: calc(50% - 15px) !important;
        display: none !important;
      }
    }
  }

  .mat-menu-content {
    .selection-group {
      .mat-ripple {
        display: none !important;
      }

      padding-left: 12px;

      & > .mat-checkbox {
        margin-bottom: 10px !important;
        display: block;
      }

      ul {
        li {
          margin-bottom: 10px !important;
        }
      }
    }
  }

  .menu-group {
    .menu-group-heading {
      background-color: #f0f0f0;
      padding: 10px;
      color: $black2;
      @extend .text-xs;
      line-height: 24px;
      font-weight: 500;
      text-align: center;
    }
  }
}

.chips-list {
  ul {
    list-style: none;
    padding-left: 0px;
    margin-top: 0px;
    display: inline-flex;
    width: 100%;
    flex-wrap: wrap;

    li {
      flex: 0 0 50%;
      width: 50%;

      .chip {
        background-color: #eaf4ff;
        line-height: 26px;
        color: $black2;
        border-radius: 0px;
        margin: 3px !important;
        min-height: 22px;
        padding: 6px !important;
        @extend .text-xs;
        display: flex;
        align-items: center;
        justify-content: space-between;

        i {
          padding: 4px;
          height: 12px;
          display: flex;
          align-items: center;
          cursor: pointer;

          &:hover {
            background-color: #dcebfd;
            color: #000;
          }
        }

        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }
      }
    }
  }
}

.selection-group {
  overflow: auto;
  height: 136px;
}

.menu-footer {
  padding: 10px 15px;
  display: flex;
  justify-content: flex-end;
}

// mat menu end

// mat table start
.mat-table-overflow {
  height: calc(100vh - 238px);
  overflow: auto;
}
.mat-table-theme {
  &.mat-table-bordered {
    border-collapse: collapse !important;
 
    th,td {
      border: 1px solid #d5d5d5;
    }
  }

  &.horizontal-overflow-width {
    min-width: 1600px;
  }

  width: 100%;

  .mat-table-sticky {
    &.mat-table-sticky-border-elem-left,
    &.sticky-left {
      &::after {
        content: "";
        position: absolute;
        height: 100%;
        width: 6px;
        box-shadow: 4px 0px 5px #0000002b;
        border-right: 1px solid #d1d1d1;
        right: -1px;
        top: 0px;
      }
    }
    &.mat-table-sticky-border-elem-right,
    &.sticky-right {
      &::after {
        content: "";
        position: absolute;
        height: 100%;
        width: 6px;
        box-shadow: -3px 0px 5px #0000002b;
        border-left: 1px solid #d1d1d1;
        left: -1px;
        top: 0px;
      }
    }
  }

  thead {
    tr {
      background-color: #eaeaea;
      height: 48px;

      th {
        padding: 6px 10px !important;
        border-bottom-color: #d5d5d5;
        color: #3a3a3a;
        @extend .text-xs;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 10px !important;
        @extend .text-sm;
        color: #3a3a3a !important;
        vertical-align: top;

        .link {
          color: $blue3;
          cursor: pointer;
          display: block;
          margin-bottom: 2px;
          margin-top: 0px;
        }

        p {
          @extend .text-xs;
          margin-bottom: 2px;
          margin-top: 0px;
        }

        .note {
          @extend .text-xs;
          margin-bottom: 2px;
          color: #6f6f6f;
        }

        .blue-icon {
          @extend .text-lg;
          color: $blue3;
          cursor: pointer;
        }

        .action-group {
          display: inline-flex;
          flex-direction: column;

          .action-btn {
            display: block;
            @extend .text-xs;
            padding: 5px;
            cursor: pointer;
            margin-bottom: 2px;
            border-radius: 4px;
            display: flex;
            white-space: pre;
            align-items: center;

            &:hover {
              background-color: rgba(0, 0, 0, 0.04);
            }

            i {
              font-size: 14px;
              margin-right: 5px;
            }
          }
        }

        .status-label-group {
          margin-bottom: 10px;

          .status-label {
            @extend .text-xs;
            padding: 2px 10px;
            font-weight: 500;
            display: inline-block;
            background-color: rgba(0, 0, 0, 0.04);
            border-radius: 4px;
          }

          .success {
            background-color: #e1ffde;
            color: #3c9d11;
          }

          .danger {
            background-color: #ffdede;
            color: $red;
          }

          .primary {
            background-color: #def4ff;
            color: #2370d9;
          }
        }

        .status-dot-group {
          .status-dot {
            display: flex;
            align-items: center;
            @extend .text-xs;
            svg {
              height: 12px;
              width: 12px;
              margin-right: 6px;
            }
            &.warning {
              svg {
                fill: #ddbb00;
              }
            }
            &.success {
              svg {
                fill: #16ac02;
              }
            }
            &.danger {
              svg {
                fill: #ff2f2f;
              }
            }
          }
        }
      }
    }
  }
}

// mat table end
// common tooltip start
/* Tooltip container */
.atom-tooltip {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  width: fit-content;
  @extend .text-xs;

  i {
    color: rgba(0, 0, 0, 0.38);
    margin-right: 5px;
    @extend .text-sm;
  }
}

/* Tooltip text */
.atom-tooltip .atom-tooltiptext {
  visibility: hidden;
  display: none;
  width: 120px;
  background-color: #ffffff;
  color: #000;
  padding: 8px;
  border-radius: 6px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  left: -55px;
  top: 30px;
  font-size: 12px;
  line-height: 16px;
  box-shadow: 0px 2px 8px #00000029;
  border: 1px solid #e5e6ea;
  text-align: left;

  &.detail-tooltip {
    min-width: 270px;
  }

  h2 {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    margin-bottom: 0px;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    background: #fff;
    box-shadow: -1px -1px 5px -2px rgba(0, 0, 0, 0.5);
    left: 56px;
    transform: rotate(45deg);
    /* Prefixes... */
    top: -5px;
  }

  &.tooltip-bottom {
    &::before {
      left: 56px;
      transform: rotate(45deg);
      /* Prefixes... */
      top: -5px;
    }
  }

  &.tooltip-right {
    left: 33px;
    top: -14px;

    &::before {
      left: -6px;
      transform: rotate(312deg);
      top: 17px;
      box-shadow: -3px -2px 8px -2px rgba(0, 0, 0, 0.5);
    }
  }

  &.tooltip-left {
    top: -14px;
    right: 33px;
    left: unset;

    &::before {
      top: 18px;
      box-shadow: -3px -2px 8px -2px rgba(0, 0, 0, 0.5);
      left: unset;
      right: -5px;
      transform: rotate(130deg);
    }
  }

  &.tooltip-top {
    right: -58px;
    left: unset;
    top: 29px;
    bottom: unset;

    &::before {
      top: -5px;
      box-shadow: -2px -3px 9px -2px rgba(0, 0, 0, 0.5);
      left: unset;
      right: 64px;
      transform: rotate(47deg);
    }
  }
}

/* Show the tooltip text when you mouse over the tooltip container */
.atom-tooltip:hover .atom-tooltiptext {
  visibility: visible;
  display: block;
}

// common tooltip end

// Alert start
.atom-alert {
  width: 415px;
  min-height: 137px;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px !important;
  color: $black2;

  &.default {
    .btn-primary {
      background-color: $red !important;
    }

    .btn-outline-primary {
      border-color: $red !important;
      color: $red !important;
      background-color: transparent !important;
    }
  }

  &.success {
    background-color: #ebf7ee;
    border: 1px solid #30a515;

    .btn-primary {
      background-color: #30a515 !important;
    }

    .btn-outline-primary {
      border-color: #30a515 !important;
      color: #30a515 !important;
      background-color: transparent !important;
    }

    .alert-close {
      i {
        color: #30a515 !important;
      }
    }
  }

  &.error {
    background-color: #f5d9da;
    border: 1px solid $red;

    .btn-primary {
      background-color: $red !important;
    }

    .btn-outline-primary {
      border-color: $red !important;
      color: $red !important;
      background-color: transparent !important;
    }

    .alert-close {
      i {
        color: $red !important;
      }
    }
  }

  &.warning {
    background-color: #fef7ea;
    border: 1px solid #ef9400;

    .btn-primary {
      background-color: #ef9400 !important;
    }

    .btn-outline-primary {
      border-color: #ef9400 !important;
      color: #ef9400 !important;
      background-color: transparent !important;
    }

    .alert-close {
      i {
        color: #ef9400 !important;
      }
    }
  }

  &.information {
    background-color: #e5effa;
    border: 1px solid #0779ff;

    .btn-primary {
      background-color: #0779ff !important;
    }

    .btn-outline-primary {
      border-color: #0779ff !important;
      color: #0779ff !important;
      background-color: transparent !important;
    }

    .alert-close {
      i {
        color: #0779ff !important;
      }
    }
  }

  .alert-section {
    width: 100%;
    display: block;

    .alert-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;

      .alert-heading {
        h2 {
          font-size: 16px;
          line-height: 14px;
          font-weight: 500;
          margin-bottom: 0;
          color: $black2;
        }

        h3 {
        }
      }
    }

    .alert-content {
      margin-bottom: 25px;

      h3 {
        font-size: 14px;
        line-height: 14px;
        color: $black2;
      }
    }

    .alert-close {
      font-size: 20px;
      min-width: auto;
      border-radius: 50%;
      height: auto;
      padding: 0px;
      line-height: normal;

      .mat-ripple,
      .mat-button-focus-overlay {
        display: none;
      }
    }

    .alert-footer {
      display: flex;
    }

    .alert-label {
      font-size: 14px;
      line-height: 14px;
      color: $blue2 !important;
      font-weight: 500;
    }
  }
}

// Alert end

// Toast start
.atom-toast {
  min-width: 152px !important;
  min-height: 48px !important;
  background-color: #fff;
  color: $input-black;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 8px !important;
  width: auto !important;

  &.default {
    background-color: #fff;

    .toast-header {
      i {
        color: #0779ff;
        background-color: #daebff;
      }
    }
  }

  &.success {
    background-color: #ebf7ee;

    .toast-header {
      i {
        color: #ffffff;
        background-color: #30a515;
      }
    }
  }

  &.error {
    background-color: #f5d9da;

    .toast-header {
      i {
        color: #ffffff;
        background-color: #d7242d;
      }
    }
  }

  &.warning {
    background-color: #fef7ea;

    .toast-header {
      i {
        color: #ffffff;
        background-color: #ef9400;
      }
    }
  }

  &.information {
    background-color: #e5effa;

    .toast-header {
      i {
        color: #ffffff;
        background-color: #0779ff;
      }
    }
  }

  .toast-header {
    display: flex;
    align-items: center;

    i {
      padding: 5px;
      margin-right: 10px;
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      border-radius: 4px;
    }

    .toast-heading {
      h2 {
        font-size: 14px;
        line-height: 14px;
        font-weight: 500;
        margin-bottom: 0;
        color: $black2;
        margin-top: 0px !important;
      }
    }
  }
}

// Toast end

// tabs and accordions start
.separate-tab {
  .mat-tab-body-wrapper {
    padding: 20px 10px;
  }

  .mat-tab-labels {
    cursor: pointer;
  }

  .mat-tab-label {
    box-shadow: 0px 3px 6px #0000000d;
    border: 0.5px solid #b5b5b5;
    border-radius: 5px;
    opacity: 1;
    margin-right: 12px;
    font-size: 14px;
    line-height: 19px;
    font-weight: 500;
    height: 40px;

    i {
      color: rgba(0, 0, 0, 0.87);
      margin-right: 10px;
    }

    &.mat-tab-label-active {
      background-color: #2f67e9;
      color: #fff;
      border-color: #2f67e9;

      i {
        color: white;
      }
    }
  }

  .mat-tab-header {
    border-bottom: unset !important;
  }

  .mat-ink-bar {
    display: none !important;
  }
}

.combine-tab {
  @extend .separate-tab;

  .mat-tab-label {
    margin-right: unset;

    border-radius: unset;

    &:first-child {
      border: 0.5px solid #b5b5b5;
      border-right: unset;
      border-radius: 8px 0px 0px 8px;
    }

    &:last-child {
      border-radius: 0px 8px 8px 0px;
      border-left: unset;
    }
  }
}

.outline-tab {
  .mat-tab-body-wrapper {
    padding: 20px 10px;
  }

  .mat-tab-labels {
    cursor: pointer;
  }

  .mat-tab-label {
    opacity: 1;

    i {
      margin-right: 10px;
    }

    &.mat-tab-label-active {
      color: #fff;
      border-color: #2f67e9;
      color: #2f67e9;

      i {
        color: #2f67e9;
      }
    }
  }

  .mat-tab-label-container {
    .mat-ink-bar {
      background-color: #2f67e9;
    }
  }
}

.icon-accordion {
  .mat-expansion-panel-header {
    padding: 0 10px;

    i {
      margin-right: 10px;
    }
  }

  .mat-expansion-panel {
    box-shadow: 0px 3px 6px #0000000d;
    border: 0.5px solid #b5b5b5;
    border-radius: 5px !important;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    mat-panel-title {
      color: #363636;
      font-size: 14px;
      line-height: 19px;
      font-weight: 500;
    }

    &.mat-expanded {
      mat-expansion-panel-header {
        background-color: #f3f4f6 !important;
        height: 48px !important;
        border-radius: 0px !important;
        border-bottom: 0.5px solid #b5b5b5;
      }

      .mat-expansion-panel-body {
        padding-top: 20px;
      }

      .mat-expansion-indicator::after {
        content: "\f1af";
      }
    }

    .mat-expansion-indicator::after {
      border: unset;
      transform: unset;
      content: "\ea13";
      font-family: remixicon !important;
      font-style: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-weight: 500;
      font-size: 20px;
      line-height: 19px;
      color: #363636;
    }
  }

  .mat-expansion-panel-body {
    p {
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
    }
  }

  .mat-expansion-indicator {
    transform: unset !important;
  }

  &.border-saperate-accordion {
    .mat-expansion-panel-spacing {
      margin: 0px;
    }
 
    .mat-expansion-panel {
      margin-bottom: 0;
      border: 1px solid #ccc;
      border-bottom: none;
      border-radius: 0px !important; /* Adjust the value as needed */
 
      &:first-of-type {
        border-radius: 8px 8px 0 0 !important;
      }
 
      &:last-of-type {
        border-bottom: 1px solid #ccc;
        border-radius: 0 0 8px 8px !important;
      }
 
      &:only-of-type {
        border: 1px solid #ccc;
        border-radius: 8px !important; /* Adjust the value as needed */
      }
    }
  }
}

// tabs and accordions end

// layout start

.atom-card {
  .card-heading {
    @extend .text-lg;
    font-weight: 500 !important;
  }

  border-radius: 0px !important;
}
.grid-name {
  padding: 10px;
  width: 100%;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  border: 2px solid #df5b65;
  border-radius: 6px;
}

// breadcumb start
.atom-breadcrumb {
  display: flex;
  border-radius: 4px;
  width: fit-content;
  &.bordered-breadcrumb {
    border: 1px solid #d1d5db;
    padding: 12px 15px;
  }
  .breadcrumb-item {
    display: flex;
    align-items: center;
    padding-left: 5px;
    &:first-child {
      padding-left: 0px;
    }
    &:last-child {
      .rightarrow {
        display: none !important;
      }
      .item-name {
        margin-right: 0px;
        font-weight: 500;
      }
    }

    i {
      font-size: 22px;
    }
    .item-name {
      text-decoration: none;
      color: #3a3a3a;
      font-size: 14px;
      line-height: 19px;
      margin-right: 10px;
      cursor: pointer;
      &:hover {
        color: #0580fa;
      }
    }
  }
}
// breadcumb end

//modal start
.atom-dialog-close {
  font-size: 25px;
  cursor: pointer;
  display: block;
}
.cdk-global-scrollblock {
  overflow: hidden;
}
.mat-dialog-container {
  h3 {
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  p {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    margin-bottom: 5px;
  }
}
.content-area {
  height: 100%;
  overflow: auto;
  background-color: #0d7ffa69;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: 400;
  color: #000;
}
.bottom-modal {
  max-width: 100vw !important;
  width: 100vw;
  &.max-bottom-modal {
    height: 90vh;
    min-height: 90vh;
    .mat-dialog-content {
      height: calc(90vh - 278px);
    }
  }
  &.meduim-bottom-modal {
    height: 80vh;
    min-height: 80vh;
    .mat-dialog-content {
      height: calc(80vh - 278px);
    }
  }
  &.small-bottom-modal {
    height: 70vh;
    min-height: 70vh;
    .mat-dialog-content {
      height: calc(70vh - 277px);
    }
  }
  .mat-dialog-container {
    border-radius: 8px 8px 0px 0px !important;
  }
  .atom-dialog-header {
    h2 {
      @extend .text-base;
      font-weight: 600;
    }
  }
}
.side-modal {
  max-height: 100vw !important;
  height: 100vw;
  &.max-side-modal {
    width: 60vw;
    min-width: 60vw;
    .mat-dialog-content {
      height: calc(100vh - 325px);
      margin-bottom: 10px;
      max-height: unset;
    }
  }
  &.meduim-side-modal {
    width: 46vw;
    min-width: 46vw;
    .mat-dialog-content {
      height: calc(100vh - 245px);
      margin-bottom: 10px;
      max-height: unset;
    }
  }
  .mat-dialog-container {
    border-radius: 8px 0px 0px 8px !important;
  }
  .atom-dialog-header {
    h2 {
      @extend .text-base;
      font-weight: 600;
    }
  }

  .mat-dialog-actions-align-end {
    height: 60px;
    padding: 0 24px;
    box-shadow: 2px -6px 9px #5e5d5d38;
    border-top: 1px solid #d1d1d1;
    margin: 0 -24px;
    min-height: unset;
  }
}
.center-modal {
  position: relative !important;
  max-height: 1000px !important;
  .atom-dialog-header {
    h2 {
      @extend .text-base;
      font-weight: 600;
      margin-bottom: 0;
    }
  }
  .mat-dialog-actions-align-end {
    height: 57px;
    padding: 0 24px;
    margin: 0 -24px;
    min-height: unset;
    box-sizing: border-box;
    position: absolute;
    bottom: 0px;
    width: 100%;
    box-shadow: 2px -6px 9px #5e5d5d38;
    border-top: 1px solid #d1d1d1;
  }
  &.confirmation-center-modal {
    .mat-dialog-container {
      border-radius: 8px !important;
    }
    .mat-dialog-content {
      display: flex;
      flex-direction: column;
      height: calc(100% - 44px);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 24px;
      line-height: 30px;
      color: #3a3a3a;
      text-align: center;
      i {
        font-size: 25px;
        color: #6f6f6f;
        margin-bottom: 15px;
      }
    }
  }
  &.center-field-modal {
    .mat-dialog-actions-align-end {
      box-shadow: 2px -6px 9px #5e5d5d38;
      border-top: 1px solid #d1d1d1;
    }
    .mat-dialog-content {
      margin-bottom: 33px;
    }
  }
}
//modal end

// date picker start

.mat-form-field-appearance-outline.mat-focused.mat-warn
  .mat-form-field-outline-thick {
  color: #0580fa !important;
}

.mat-datepicker-content.mat-warn {
  .atom-datepicker {
    .mat-calendar-body-selected {
      background-color: $red;
    }
  }
}
.atom-datepicker {
  .mat-calendar-body-label,
  .mat-calendar-body-cell-content {
    color: black;
  }
  .mat-calendar-body-cell {
    height: 100% !important;
  }

  .mat-calendar-table-header {
    th {
      color: black;
      font-size: 12px;
      font-weight: 500;
    }
  }
  .mat-calendar-header {
    padding: 0px 3px;
    background-color: #f0f0f0;
  }
  .mat-calendar-controls {
    margin-top: 0px;
    .mat-calendar-arrow {
      width: 0px !important;
      height: 0px !important;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top-width: 5px;
      border-top-style: solid;
      margin: 0 0 0 5px;
    }
  }
}
.touch-ui {
  position: absolute;
  width: 128px;
  left: -125px;
  background: white;
  z-index: -1;

  .mat-card {
    height: 309px;
    border-radius: 4px 4px 0px 0px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
  .mat-button {
    color: $red;
    font-size: 12px;
    width: 100%;
    text-align: left;
  }
}

.date-range-header {
  display: flex;
  align-items: center;
  padding: 5px;
  background-color: #f0f0f0;
  margin-bottom: 12px;
  button {
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    font-size: 24px;
  }
  .date-range-double-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    i {
      width: 10px;
      height: auto;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.date-range-header-label {
  flex: 1;
  height: 1em;
  font-weight: 500;
  text-align: center;
}

.date-range-double-arrow .mat-icon {
  margin: -22%;
}
.custom-date-range-picker-dropdown {
  .mat-calendar-body-cell {
    position: absolute;
    height: 100%;
  }
}
.date-time-picker-dropdown {
  .mat-calendar-body-cell-content.mat-calendar-body-selected {
    background-color: $red;
  }
  .mat-calendar-body-cell {
    position: relative;
  }
  .mat-calendar-controls {
    margin: 0px 0px 15px;
  }
  .mat-calendar-arrow {
    width: 0 !important;
    height: 0 !important;
  }
  & + .time-container {
    .mat-icon-button {
      mat-icon {
        display: none !important;
      }
      &[aria-label="expand_less icon"] {
        font-size: 24px;
        height: 34px !important;
        width: 34px !important;
        &::after {
          content: "\ea78";
          font-family: remixicon !important;
          font-style: normal;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }
      &[aria-label="expand_more icon"] {
        font-size: 24px;
        height: 34px !important;
        width: 34px !important;
        &::after {
          content: "\ea4e";
          font-family: remixicon !important;
          font-style: normal;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      }
    }
  }
  & + .time-container + .actions {
    .mat-button {
      font-size: 20px;
      color: $red;
      border: unset;
      &::after {
        content: "Ok";
        font-family: Roboto, "Helvetica Neue", sans-serif;
        font-size: 14px;
        font-weight: 500;
        // font-family: remixicon!important;
        // font-style: normal;
        // -webkit-font-smoothing: antialiased;
        // -moz-osx-font-smoothing: grayscale;
      }
      .mat-icon {
        display: none !important;
      }
    }
  }
}

// date picker end

//header start
.atom-header {
  position: relative;
  nav {
    display: flex;
    width: 100%;
    box-shadow: 0px 3px 15px #0000000f;
    padding: 0px 40px;
    height: 77px;
    box-sizing: border-box;
    align-items: center;
    position: relative;
    @include xl {
      width: 100vw;
    }
    .atom-icon-anchor {
      display: flex;
      align-items: center;

      img {
        // width: 130px;
        // height: 40px;
      }
    }
    .atom-navbar-list {
      margin-left: 30px;
      width: 100%;
      display: flex;
      z-index: 99;
      ul {
        list-style: none;
        display: inline-flex;
        width: 100%;
        align-items: center;

        li {
          position: relative;
          display: flex;
          align-items: center;
          &:not(:last-child) {
            margin-right: 10px;
          }
          &.separation-line {
            &::after {
              content: "";
              position: relative;
              border-right: 2px solid #cccccc;
              margin: 0px 15px;
              height: 21px;
              display: inline-block;
            }
          }
          a {
            text-decoration: unset !important;

            &:not(.anchor, .btn-primary) {
              color: #3a3a3a;
              padding: 10px;
              font-size: 14px;
              line-height: 16px;
              font-weight: 500;
              border-radius: 8px;
              padding: 10px 20px;
              display: flex;
              white-space: pre;
              &.active {
                color: #0779ff;
              }
              &.btn-primary {
                height: 40px !important;
                border-radius: 8px !important;
                box-shadow: unset !important;
                padding: 0px 40px !important;
              }
            }

            &.anchor {
              color: #3067e9;
              font-size: 16px;
              line-height: 16px;
              font-weight: 500;
            }
            &.mat-menu-trigger {
              &::after {
                content: "\ea4e";
                font-family: remixicon !important;
                font-style: normal;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                font-size: 21px;
                margin-left: 5px;
              }
              &[aria-expanded="true"] {
                &::after {
                  content: "\ea78";
                }
              }
            }
            i {
              color: #6f6f6f !important;
              font-size: 20px;
              padding: 8px;
            }
          }
        }
      }
      &.show {
        display: flex;
        flex-direction: column;
      }
      @include xl {
        position: absolute;
        display: none;
        top: 78px;
        background: #fff;
        margin: 0px;
        left: 0px;
        width: 100%;
        padding: 10px;
        ul {
          flex-direction: column;
          padding-left: 0px;
          margin: 0px;
          li {
            margin-left: unset !important;
            width: 100%;
            border-bottom: 1px solid #00000017;
            a {
              width: 100%;
              &.btn-primary {
                margin-top: 5px;
                width: auto;
              }
            }
          }
          &:not(:first-child) {
            li {
              &:last-child {
                border-bottom: unset !important;
              }
            }
          }
        }
      }
    }
  }
  .nav-strip {
    background-color: #efefef;
    ul {
      list-style: none;
      display: inline-flex;
      width: 100%;
      align-items: center;
      height: 40px;
      margin: 0px;
      padding-left: 125px;

      li {
        position: relative;
        display: flex;
        align-items: center;
        &:not(:last-child) {
          margin-right: 10px;
        }
        a {
          text-decoration: unset !important;
          color: #3a3a3a;
          padding: 10px;
          font-size: 14px;
          line-height: 16px;
          font-weight: 500;
          border-radius: 8px;
          padding: 10px 20px;

          &.active,
          &:hover {
            color: #0779ff;
          }
        }
      }
    }
  }
}
.toggle-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  display: flex;
  flex-direction: column;
  display: none;
  position: absolute;
  right: 18px;
  @include xl {
    display: flex;
  }
}
.hamburger .line {
  width: 24px;
  height: 2px;
  background-color: #000000;
  display: block;
  margin: 8px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  &:nth-child(2) {
    width: 18px;
    margin-left: 10px;
  }
}
.mat-menu-header {
  .mat-menu-item {
    line-height: 40px !important;
    height: 40px;
    font-size: 14px;
    line-height: 19px;
    color: #3a3a3a;
  }
}
.mega-menu {
  max-width: 735px !important;
  width: 735px !important;
  height: auto !important;
  h2 {
    font-size: 12px;
    line-height: 19px;
    font-weight: 500;
    margin: 10px;
  }
  .mat-menu-item {
    line-height: 40px !important;
    height: 40px;
    font-size: 14px;
    line-height: 19px;
    color: #3a3a3a;
  }
  @include xl {
    max-width: 100vw !important;
    width: 100vw !important;
  }
}
@include xl {
  .mega-menu-pane-class {
    & + .cdk-overlay-connected-position-bounding-box {
      .cdk-overlay-pane {
        left: 0px !important;
      }
    }
  }
}
.hamburger:hover {
  cursor: pointer;
}

/* ONE */
#hamburger-1.is-active {
  .line {
    background-color: #000;
  }
}

#hamburger-1.is-active .line:nth-child(2) {
  opacity: 0;
}

#hamburger-1.is-active .line:nth-child(1) {
  -webkit-transform: translateY(13px) rotate(45deg);
  -ms-transform: translateY(13px) rotate(45deg);
  -o-transform: translateY(13px) rotate(45deg);
  transform: translateY(13px) rotate(45deg);
}

#hamburger-1.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-7px) rotate(-45deg);
  -ms-transform: translateY(-7px) rotate(-45deg);
  -o-transform: translateY(-7px) rotate(-45deg);
  transform: translateY(-7px) rotate(-45deg);
}
//header end
// sidebar start
.atom-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 260px;
  z-index: 999;
  transition: all 0.5s ease;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
}

.atom-sidebar.close {
  width: 65px;
}
.atom-sidebar .logo-details {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
}
.atom-sidebar .logo-details i {
  font-size: 30px;
  color: #363636;
  height: 50px;
  min-width: 65px;
  text-align: center;
  line-height: 50px;
}
.atom-sidebar .logo-details .logo_name {
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  transition: 0.3s ease;
  transition-delay: 0.1s;
}
.atom-sidebar.close .logo-details .logo_name {
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
}
.atom-sidebar .nav-links {
  height: 100%;
  padding: 30px 0 0px 0;
  overflow: auto;
  box-sizing: border-box;
  margin: 0px;
}
.atom-sidebar.close .nav-links {
  overflow: visible;
}
.atom-sidebar .nav-links li {
  position: relative;
  list-style: none;
  transition: all 0.4s ease;

  // &:not(:last-of-type){
  //   margin-bottom: 5px;
  // }
}
.atom-sidebar .nav-links > li.active > a:before,
.atom-sidebar .nav-links > li:hover > a:before,
.atom-sidebar .nav-links > li.active > .iocn-link:before,
.atom-sidebar .nav-links > li:hover > .iocn-link:before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  width: 4px;
  height: 100%;
  background: #da191e;
  opacity: 0;
  transition: all 0.25s ease-in-out;
  border-top-right-radius: 5px;
  border-top-right-radius: 5px;
}

.atom-sidebar .nav-links li.active > a:before,
.atom-sidebar .nav-links li:hover > a:before,
.atom-sidebar .nav-links li.active > .iocn-link:before,
.atom-sidebar .nav-links li:hover > .iocn-link:before {
  opacity: 1;
}
.atom-sidebar .nav-links li .iocn-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: white;
}
.atom-sidebar:not(.close) .nav-links li.active .iocn-link {
  border-top: 1px solid #ffacae;
  border-bottom: 1px solid #ffacae;
}
.atom-sidebar.close .nav-links li .iocn-link {
  display: block;
}
.atom-sidebar .nav-links li i {
  height: 50px;
  min-width: 45px;
  text-align: center;
  line-height: 50px;
  color: #363636;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.atom-sidebar .nav-links li.active i,
.atom-sidebar .nav-links li:hover i,
.atom-sidebar .nav-links li.active .link_name,
.atom-sidebar .nav-links li:hover .link_name {
  // color: #da191e !important;
}
.atom-sidebar .nav-links li.showMenu > .iocn-link i.arrow {
  transform: rotate(-180deg);
}
.atom-sidebar.close .nav-links i.arrow {
  display: none;
}
.atom-sidebar .nav-links li a {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.atom-sidebar .nav-links li a .link_name {
  font-size: 14px;
  font-weight: 400;
  color: #363636;
  transition: all 0.4s ease;
  white-space: pre;
  font-weight: 500;
}
.atom-sidebar .nav-links li.active > a,
.atom-sidebar .nav-links li:hover > a,
.atom-sidebar .nav-links li.active > .iocn-link,
.atom-sidebar .nav-links li:hover > .iocn-link {
  background-color: #ffefef;
}

.atom-sidebar .nav-links li:hover a {
  // color: #da191e;
}
.atom-sidebar.close .nav-links li a .link_name {
  opacity: 0;
  pointer-events: none;
}
.atom-sidebar .nav-links li .sub-menu {
  // margin-top: -10px;
  background: #fff;
  display: none;
}

.atom-sidebar .nav-links li.showMenu > .sub-menu {
  display: block;
  margin-top: -1px;
  .iocn-link {
    background-color: #ffefef;
  }
}
.atom-sidebar {
  .nav-links {
    li {
      ul {
        display: none;
        padding-left: 0px;
      }
      &.showMenu {
        & > ul {
          display: block;
        }
      }
    }
  }
}
.atom-sidebar .nav-links li .sub-menu a {
  color: #363636;
  font-size: 14px;
  padding: 5px 0;
  white-space: nowrap;
  height: 40px;
  min-width: 65px;
  line-height: 40px;
  transition: all 0.3s ease;
  padding-left: 35px;
}
.atom-sidebar .nav-links li > .sub-menu li:hover > a {
  background-color: white !important;
  color: red !important;
}
.atom-sidebar .nav-links li .sub-menu .sub-menu a {
  padding-left: 55px;
}
.atom-sidebar .nav-links li .sub-menu .sub-menu .sub-menu a {
  padding-left: 65px;
}
.atom-sidebar .nav-links li .sub-menu a:hover {
  opacity: 1;
}
.atom-sidebar.close .nav-links li .sub-menu {
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}
.atom-sidebar.close .nav-links li:hover > .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}
// .atom-sidebar .nav-links li > .sub-menu .link_name{
//   display: none;
// }
.atom-sidebar.close .nav-links li > .sub-menu .link_name {
  font-size: 18px;
  opacity: 1;
  display: block;
}
.atom-sidebar .nav-links li > .sub-menu.blank {
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}
.atom-sidebar .nav-links li:hover > .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
}

.atom-sidebar .nav-links li .sub-menu .showMenu .sub-menu .sub-menu,
.atom-sidebar .nav-links li .sub-menu .showMenu .sub-menu .sub-menu li:hover a {
  background: #fafafa !important;
}

.atom-sidebar.close .nav-links li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}

.hamburger-sidebar {
  font-size: 26px;
  padding: 10px;
  margin-bottom: 16px;
  display: inline-block;
}

// sidebar end

// input file

.atom-input-file-btn {
  max-width: 160px;
  padding: 0px !important;
  .mat-button-wrapper {
    height: 100%;
    max-width: 100%;
    justify-content: flex-start !important;
  }
  .atom-select-file{
    span {
      display: flex;
      height: 100%;
      align-items: center;
    }
    input{
      width: 100%;
      height: 100%;
    }
  }
  .atom-select-file,
  .file-name-section {
    width: 100%;
    height: 100%;
    padding: 0 15px !important;
    display: flex;
    align-items: center;
  
    i {
      font-size: 16px !important;
      line-height: 22px !important;
    }
  }
  .file-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: pre;
    width: 74%;
    display: block;
    line-height: 32px;
    a{
      padding: 4px;
      display: block;
      height: 100%;
      line-height: 33px;
    }
  }
}
