//Rubik and Roboto (combined link)
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&family=Rubik:wght@300;400;500;600;700;800;900&display=swap");

*{
  font-family: $roboto ;
}
html{
  height: 100%;

}
body {
  // font-family: $rubik;
   font-family: $roboto !important;
  margin: 0px;
  height: 100%;
  box-sizing: border-box; 
}
//----------------------------------------headings

// font sizes
.text-xs {
  font-size: 12px !important; /* 12px */
  line-height: 16px !important; /* 16px */
}

.text-sm {
  font-size: 14px !important; /* 14px */
  line-height: 20px !important; /* 20px */
}
.text-base {
  font-size: 16px !important;; /* 16px */
  line-height: 24px !important;; /* 24px */
}
.text-lg {
  font-size: 18px !important; /* 18px */
  line-height: 28px !important; /* 28px */
}
//---------------------------------------------- // font weight 
.font-300{
font-weight: 300 !important;
}
.font-400{
  font-weight: 400 !important;
}
.font-500{
  font-weight: 500 !important;
}
.font-600{
  font-weight: 600 !important;
}
.font-700{
  font-weight: 700 !important;
}
.font-800{
  font-weight: 800 !important;
}
.font-900{
  font-weight: 900 !important;
}

//--------------------------------------------position
.static {
  position: static;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.sticky {
  position: sticky;
}

//------------------------------------------.display
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.inline {
  display: inline;
}
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.hidden {
  display: none !important;
}


//------------------------------------------Alignmnet
.v-top{
  vertical-align: top;
}
.v-mid{
  vertical-align: middle;
}

//--------------------------------------------------flex box
.flex-row {
  flex-direction: row !important;
}
.flex-row-reverse {
  flex-direction: row-reverse !important;
}
.flex-col {
  flex-direction: column !important;
}
.flex-col-reverse {
  flex-direction: column-reverse !important;
}
.justify-start {
  justify-content: flex-start !important;
}
.justify-end {
  justify-content: flex-end !important;
}
.justify-center {
  justify-content: center !important;
}
.justify-between {
  justify-content: space-between !important;
}
.justify-around {
  justify-content: space-around !important;
}
.justify-evenly {
  justify-content: space-evenly !important;
}

.items-start {
  align-items: flex-start !important;
}
.items-end {
  align-items: flex-end !important;
}
.items-center {
  align-items: center !important;
}
//----------------------------------------------------------width
.w-auto {
  width: auto;
}
.w-50 {
  width: 50% !important;
}
.w-33 {
  width: 33.333333%;
}
.w-66 {
  width: 66.666667%;
}
.w-25 {
  width: 25%;
}
.w-75 {
  width: 75%;
}
.w-full {
  width: 100% !important;
}
.w-vw{
width: 100vw !important;
}
.img-fluid{
max-width: 100%;
width : 100%;
}

//----------------------------------------------------------------height

.h-auto {
  height: auto;
}
.h-50 {
  height: 50%;
}
.h-33 {
  height: 33.333333%;
}
.h-66 {
  height: 66.666667%;
}
.h-25 {
  height: 25%;
}
.h-75 {
  height: 75%;
}
.h-full {
  height: 100%;
}
//----------------------------------------------text alignment
.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}
//--------------------------------------------------overflow

.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}

//-----------------------------------------------padding 0
.p-0 {
  padding: 0px !important;
}
.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.pt-0 {
  padding-top: 0px !important;
}
.pr-0 {
  padding-right: 0px !important;
}
.pb-0 {
  padding-bottom: 0px !important;
}
.pl-0 {
  padding-left: 0px !important;
}

//--------------------------------------------------- padding 5
.p-5 {
  padding: 5px !important; /* 5px */
}
.pl-5 {
  padding-left: 5px !important; /* 5px */
}
.pr-5 {
  padding-right: 5px !important; /* 5px */
}
.px-5 {
  padding-left: 5px !important; /* 5px */
  padding-right: 5px !important; /* 5px */
}

.py-5 {
  padding-top: 5px !important; /* 5px */
  padding-bottom: 5px !important; /* 5px */
}
//--------------------------------------------------- padding 10
.p-10 {
  padding: 10px !important; /* 10px */
}

.pl-10 {
  padding-left: 10px !important; /* 10px */
}

.pr-10 {
  padding-right: 10px !important; /* 10px */
}

.px-10 {
  padding-left: 10px !important; /* 10px */
  padding-right: 10px !important; /* 10px */
}

.py-10 {
  padding-top: 10px !important; /* 10px */
  padding-bottom: 10px !important; /* 10px */
}
//--------------------------------------------------- padding 15
.p-15 {
  padding: 15px !important; /* 15px */
}

.pl-15 {
  padding-left: 15px !important; /* 15px */
}

.pr-15 {
  padding-right: 15px !important; /* 15px */
}

.px-15 {
  padding-left: 15px !important; /* 15px */
  padding-right: 15px !important; /* 15px */
}

.py-15 {
  padding-top: 15px !important; /* 15px */
  padding-bottom: 15px !important; /* 15px */
}
//--------------------------------------------------- padding 20
.p-20 {
  padding: 20px !important; /* 20px */
}

.pl-20 {
  padding-left: 20px !important; /* 20px */
}

.pr-20 {
  padding-right: 20px !important; /* 20px */
}

.px-20 {
  padding-left: 20px !important; /* 20px */
  padding-right: 20px !important; /* 20px */
}

.py-20 {
  padding-top: 20px !important; /* 20px */
  padding-bottom: 20px !important; /* 20px */
}
//--------------------------------------------------- padding 30
.p-30 {
  padding: 30px !important; /* 30px */
}

.pl-30 {
  padding-left: 30px !important; /* 30px */
}

.pr-30 {
  padding-right: 30px !important; /* 30px */
}

.px-30 {
  padding-left: 30px !important; /* 30px */
  padding-right: 30px !important; /* 30px */
}

.py-30 {
  padding-top: 30px !important; /* 30px */
  padding-bottom: 30px !important; /* 30px */
}

//margin
.mx-auto {
  margin: auto;
}
//----------------------------------------------------- Margin 0
.ml-auto{
margin-left: auto !important;
}
.m-0 {
  margin: 0px !important;
}
.mt-0 {
  margin-top:  0px !important;
}
.mb-0 {
  margin-bottom:  0px !important;
}
.ml-0 {
  margin-left:  0px !important;
}

.mr-0 {
  margin-right:  0px !important;
}

.mx-0 {
  margin-left:  0px !important;
  margin-right:  0px !important;
}

.my-0 {
  margin-top:  0px !important;
  margin-bottom:  0px !important;
}





//----------------------------------------------------- Margin 5
.m-5 {
  margin: 5px !important; /* 5px */
}
.mt-5 {
  margin-top: 5px !important; /* 5px */
}
.mb-5{
margin-bottom: 5px !important; /* 5px */
}
.ml-5 {
  margin-left: 5px !important; /* 5px */
}

.mr-5 {
  margin-right: 5px !important; /* 5px */
}

.mx-5 {
  margin-left: 5px !important; /* 5px */
  margin-right: 5px !important; /* 5px */
}

.my-5 {
  margin-top: 5px !important; /* 5px */
  margin-bottom: 5px !important; /* 5px */
}

//----------------------------------------------------- Margin 10
.m-10 {
  margin: 10px !important; /* 10px */
}

.ml-10 {
  margin-left: 10px !important; /* 10px */
}
.mt-10 {
  margin-top: 10px !important; /* 10px */
}

.mr-10 {
  margin-right: 10px !important; /* 10px */
}

.mx-10 {
  margin-left: 10px !important; /* 10px */
  margin-right: 10px !important; /* 10px */
}

.my-10 {
  margin-top: 10px !important; /* 10px */
  margin-bottom: 10px !important; /* 10px */
}
.mb-10 {
  margin-bottom: 10px !important; /* 10px */

}
//----------------------------------------------------- Margin 15
.m-15 {
  margin: 15px !important; /* 15px */
}
.mt-15 {
  margin-top: 15px !important; /* 15px */
}
.mb-15 {
  margin-bottom: 15px !important; /* 15px */
}
.ml-15 {
  margin-left: 15px !important; /* 15px */
}

.mr-15 {
  margin-right: 15px !important; /* 15px */
}

.mx-15 {
  margin-left: 15px !important; /* 15px */
  margin-right: 15px !important; /* 15px */
}

.my-15 {
  margin-top: 15px !important; /* 15px */
  margin-bottom: 15px !important; /* 15px */
}
//----------------------------------------------------- Margin 20
.m-20 {
  margin: 20px !important; /* 20px */
}
.mt-20 {
  margin-top: 20px !important; /* 20px */
}


.ml-20 {
  margin-left: 20px !important; /* 20px */
}

.mr-20 {
  margin-right: 20px !important; /* 20px */
}
.mb-20 {
  margin-bottom: 20px !important; /* 20px */
}


.mx-20 {
  margin-left: 20px !important; /* 20px */
  margin-right: 20px !important; /* 20px */
}

.my-20 {
  margin-top: 20px !important; /* 20px */
  margin-bottom: 20px !important; /* 20px */
}
//----------------------------------------------------- Margin 30
.m-30 {
  margin: 30px !important; /* 30px */
}

.ml-30 {
  margin-left: 30px !important; /* 30px */
}

.mr-30 {
  margin-right: 30px !important; /* 30px */
}
.mt-30 {
  margin-top: 30px !important; /* 30px */
}
.mb-30 {
  margin-bottom: 30px !important; /* 30px */
}

.mx-30 {
  margin-left: 30px !important; /* 30px */
  margin-right: 30px !important; /* 30px */
}

.my-30 {
  margin-top: 30px !important; /* 30px */
  margin-bottom: 30px !important; /* 30px */
}
//-------------------------------------------------------color
.text-white {
  color: white;
}
.text-dark-black {
  color: $dark-black;
}
.text-black {
  color: $black !important;
}
.text-blue {
  color: $blue;
}
.text-red {
  color: $red;
}
.input-text{
  color: $input-black;
}
//-----------------------------------------------------bg color
html .bg-current {
  background-color: $red !important;
}
html .bg-white {
  background-color: $white;
}
html .bg-dark-black {
  background-color: $dark-black !important;
}
html .bg-black {
  background-color: $black !important;
}
//-----------------------------------------------------raduis
.rounded-none{
  border-radius: 0px !important;
}
.rounded-sm{
  border-radius: 2px !important; /* 2px */
}
.rounded{
  border-radius: 4px !important; /* 4px */
}
.rounded-md{
  border-radius: 6px !important; /* 6px */
}
.rounded-lg{
  border-radius: 8px !important; /* 8px */
}
//----------------------------------------------------- border
.input-border{
  border: 1px solid $lighGrey2 !important;
}
//----------------------------------------------------- box shadow

.box-shadow-unset{
box-shadow: unset !important;
}
//----------------------------------------------------- layout

.atom-row{
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  margin: 0px -10px;

  &.g-20{
    margin: 0px -20px !important;
    [class*="atom-col-"]{
      padding: 0px 20px !important;
    }
    [class^="w-p"]{
      padding: 0px 20px !important;
    }
  }
  &.g-10{
    margin: 0px -10px !important;
    [class*="atom-col-"]{
      padding: 0px 10px !important;
    }
    [class^="w-p"]{
      padding: 0px 10px !important;
    }
  }
  &.g-15{
    margin: 0px -15px !important;
    [class*="atom-col-"]{
      padding: 0px 15px !important;
    }
    [class^="w-p"]{
      padding: 0px 15px !important;
    }
  }
  &.g-5{
    margin: 0px -5px !important;
    [class*="atom-col-"]{
      padding: 0px 5px !important;
    }
    [class^="w-p"]{
      padding: 0px 5px !important;
    }
  }

}

// .atom-row:before,
// .atom-row:after{
// 	content: " ";
// 	display: table;
// }
// .atom-row:after{
// 	clear: both;
// }

[class*="atom-col-"]{
	width: 100%;
	min-height: 1px;
  align-items: center;
  display: flex;
  padding: 0px 10px;
  box-sizing: border-box;

}
@media screen and (min-width: 960px){
	.atom-col-sm-1{
  -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
}
	.atom-col-sm-2{
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}
	.atom-col-sm-3{
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
	.atom-col-sm-4{
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}
	.atom-col-sm-5{
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}
	.atom-col-sm-6{
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
	.atom-col-sm-7{
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}
	.atom-col-sm-8{
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}
	.atom-col-sm-9{
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}
	.atom-col-sm-10{
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}
	.atom-col-sm-11{
   -ms-flex: 0 0 91.66667%;
   flex: 0 0 91.66667%;
   max-width: 91.66667%;
}
	.atom-col-sm-12{
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
//offset
.atom-col-offset-sm-1{
	margin-left: 8.33333%;
}
	.atom-col-offset-sm-2{
    margin-left: 16.66667%;
}
	.atom-col-offset-sm-3{
    margin-left: 25%;
}
	.atom-col-offset-sm-4{
    margin-left: 33.33333%;
}
	.atom-col-offset-sm-5{
    margin-left: 41.66667%;
}
	.atom-col-offset-sm-6{
    margin-left: 50%;
}
	.atom-col-offset-sm-7{
    margin-left: 58.33333%;
}
	.atom-col-offset-sm-8{
    margin-left: 66.66667%;
}
	.atom-col-offset-sm-9{
    margin-left: 75%;
}
	.atom-col-offset-sm-10{
    margin-left: 83.33333%;
}
	.atom-col-offset-sm-11{
    margin-left: 91.66667%;
}
	.atom-col-offset-sm-12{
    margin-left: 100%;
}
}
//----------------------------------------------------- white space classes
.white-space-pre{
white-space: pre !important;
}
.white-space-wrap{
  white-space: wrap !important;
  }